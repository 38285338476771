<template>
  <div class="position-relative">
    <table class="table-wait-account table table-bordered table-warning table-sm mb-0">
      <thead>
        <tr>
          <th rowspan="2">ชื่อใช้งาน</th>
          <th width="6%" rowspan="2">ระดับ</th>
          <th width="8%" rowspan="2" style="border-right: 2px solid #ffdf7e;">ยอดทั้งหมด</th>
          <th colspan="4" style="border-right: 2px solid #ffdf7e;">สมาชิก</th>
          <th colspan="4" style="border-right: 2px solid #ffdf7e;">{{agentName}}</th>
          <th colspan="4">บริษัท</th>
        </tr>
        <tr>
          <th width="6%" class="p-1">ส่งออก</th>
          <th width="6%" class="p-1">คอมฯ</th>
          <th width="6%" class="p-1">ถูกรางวัล</th>
          <th width="6%" class="p-1" style="border-right: 2px solid #ffdf7e;">รวม</th>
          <th width="6%" class="p-1">ถือหุ้น</th>
          <th width="6%" class="p-1">คอมฯ</th>
          <th width="6%" class="p-1">ถูกรางวัล</th>
          <th width="6%" class="p-1" style="border-right: 2px solid #ffdf7e;">รวม</th>
          <th width="6%" class="p-1">ถือหุ้น</th>
          <th width="6%" class="p-1">คอมฯ</th>
          <th width="6%" class="p-1">ถูกรางวัล</th>
          <th width="6%" class="p-1">รวม</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in accounts" :key="item.account.accountId">
          <td class="p-0 px-1 text-center">
            <a v-if="item.account.role==='Agent'" :href="'#'+marketId" @click="toggleChildAccount(item.account.accountId)">{{ item.account.username }}</a>
            <a v-else href="#member-tickets" @click="toggleMemberAccount(item.account.accountId)">{{ item.account.username }}</a>
            <small v-if="showName" class="text-secondary ml-1">({{item.account.firstName}})</small>
          </td>
          <td class="text-center">
            <AccountRole :role="item.account.role" />
          </td>
          <td class="text-right px-1 text-success" style="border-right: 2px solid #ffdf7e;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>

          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.memberAmount>0
            }, {
              'text-danger': item.summary.memberAmount<0
            }]"
          >{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.memberCommission>0
            }, {
              'text-danger': item.summary.memberCommission<0
            }]"
          >{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.memberWon>0
            }, {
              'text-danger': item.summary.memberWon<0
            }]"
          >{{ item.summary.memberWon | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.memberTotal>0
            }, {
              'text-danger': item.summary.memberTotal<0
            }]"
            style="border-right: 2px solid #ffdf7e;"
          >{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>

          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.agentAmount>0
            }, {
              'text-danger': item.summary.agentAmount<0
            }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.agentCommission>0
            }, {
              'text-danger': item.summary.agentCommission<0
            }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.agentWon>0
            }, {
              'text-danger': item.summary.agentWon<0
            }]"
          >{{ item.summary.agentWon | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1" style="border-right: 2px solid #ffdf7e;"
            :class="[{
              'text-success': item.summary.agentTotal>0
            }, {
              'text-danger': item.summary.agentTotal<0
            }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>

          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyAmount>0
            }, {
              'text-danger': item.summary.companyAmount<0
            }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyCommission>0
            }, {
              'text-danger': item.summary.companyCommission<0
            }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyWon>0
            }, {
              'text-danger': item.summary.companyWon<0
            }]"
          >{{ item.summary.companyWon | amountFormat(2, '0.00') }}</td>
          <td
            class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyTotal>0
            }, {
              'text-danger': item.summary.companyTotal<0
            }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
        </tr>
      </tbody>
    </table>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#51CE8D"
      loader="dots"
    />
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import cAlert from '@/helpers/alert'

export default {
  name: 'WinLoseByAccount2',
  props: ['showMarketId', 'filters', 'marketId', 'showName'],
  data() {
    return {
      data: null,
      loadWithaccountId: null,
      isLoading: false
    }
  },
  computed: {
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    accounts() {
      if(!this.data)
        return []

      const reports = this.data?.accounts || []

      return _.orderBy(reports, ['account.username'], ['asc'])
    },
    filterAccountId() {
      return this.filters.accountId
    },
    changed() {
      return `${this.showMarketId}-${this.filters.accountId}`
    }
  },
  watch: {
    changed() {
      if(this.showMarketId === this.marketId) {

        if(!this.data) {
          console.log('!this.data')
          this.getReports()
        }else
        if(this.filterAccountId !== this.loadWithaccountId){
          console.log('!accountId')
          this.getReports()
        }
      }
    }
  },
  methods: {
    toggleChildAccount(accountId) {
      this.$emit('toggleAccount', accountId)
    },
    toggleMemberAccount(accountId) {
      this.$emit('toggleMember', accountId, this.marketId)
    },
    getReports() {
      this.isLoading = true
      ReportService.getMemberWinLose2({
        ...this.filters,
        marketIds: [this.marketId]
      }).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.loadWithaccountId = this.filters.accountId
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        font-size: 95%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 90%;
      }
    }
  }
}
</style>
